<template>
  <v-data-table
      style="border-radius: 10px!important"
      v-bind:headers="headers"
      :items="items"
      class="ma-0 pa-0 elevation-4"
    >
    <template class="ma-0 pa-0" slot="headerCell" slot-scope="props">
      <v-tooltip bottom>
        <span slot="activator" class="ma-0 pa-0 teal--text ">
          {{ props.header.text }}
        </span>
        <span>
          {{ props.header.text }}
        </span>
      </v-tooltip>
    </template>
    <template slot="items" slot-scope="props">
      <td class="ma-0 pa-2 ">{{ props.item.number }}</td>
      <td class="ma-0 pa-0 pl-2 text-xs-left">{{ props.item.name }}</td>
      <td class="ma-0 pa-0 pr-2 text-xs-right">{{ props.item.fees }}</td>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data () {
      return {
        headers: [
          {
            text: 'No',
            align: 'left',
            sortable: false,
            value: 'No'
          },
          {
            text: 'Transaction Amount (F CFA)',
            align: 'left',
            sortable: false,
            value: 'amount'
          },
          { 
              text: 'Withdrawal Fees (F CFA)',
              align: 'right',
              sortable: false,
              value: 'fees' 
          },
        ],
        items: [
          {
            number: 1,
            value: false,
            name: '1,000 - 4,000 F CFA',
            fees: "200 F CFA",
          },
          {
            number: 2,
            value: false,
            name: '5000 - 9,000 F CFA',
            fees: "300 F CFA",
          },
          {
            number: 3,
            value: false,
            name: '	10,000 - 14,000 F CFA',
            fees: "400 F CFA",
          },
          {
            number: 4,
            value: false,
            name: '15,000 - 19,000 F CFA',
            fees: "500 F CFA",
          },
          {
            number: 5,
            value: false,
            name: '20,000 - 24,000 F CFA',
            fees: "600 F CFA",
          },
          {
            number: 6,
            value: false,
            name: '25,000 - 29,000 F CFA',
            fees: "700 F CFA",
          },
          {
            number: 7,
            value: false,
            name: '30,000 - 39,000 F CFA',
            fees: "850 F CFA",
          },
          {
            number: 8,
            value: false,
            name: '40,000 - 49,000 F CFA',
            fees: "1,000 F CFA",
          },
          {
            number: 9,
            value: false,
            name: '50,000 - 54,000 F CFA',
            fees: "1,100 F CFA",
          },
          {
            number: 10,
            value: false,
            name: '55,000 - 59,000 F CFA',
            fees: "1,200 F CFA",
          },
          {
            number: 11,
            value: false,
            name: '60,000 - 69,000 F CFA',
            fees: "1,400 F CFA",
          },
          {
            number: 12,
            value: false,
            name: '70,000 - 79,000 F CFA',
            fees: "1,600 F CFA",
          },
          {
            number: 13,
            value: false,
            name: '80,000 - 99,000 F CFA',
            fees: "2,000 F CFA",
          },
          {
            number: 14,
            value: false,
            name: '100,000 - 109,000 F CFA',
            fees: "2,500 F CFA",
          },
          {
            number: 15,
            value: false,
            name: '110,000 - 159,000 F CFA',
            fees: "3,000 F CFA",
          },
          {
            number: 16,
            value: false,
            name: '160,000 - 300,000 F CFA',
            fees: "1.5%",
          },
          {
            number: 17,
            value: false,
            name: '300,000 F CFA and > ...',
            fees: "1.2%",
          },
        ]
      }
    },
        
    mounted(){
        // executes these after the page has been mounted
        document.title = "PettyCash | Withdrawal Fees"
        this.$store.commit('setCloseURL', "/savings")
    }
  }
</script>

<style >
  th{
    padding: 12px!important;
  }
</style>
